import logoWhite from './img/logoWhite.svg';
import './App.css';
import styled from 'styled-components'

const Title = styled.h1`
font-family: 'Fjalla One', serif;
  font-size: 1.5em;
  text-align: center;
  color: #fff;
`;

const Header = styled.header`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`

const Logo = styled.img`
  max-width: 90%;
`



const App = () => (
    <div className="App">
      <Header>
        <Logo src={logoWhite} alt="logo" />
        <Title>
          2024
       </Title>
      </Header>
    </div>
  );

export default App;
